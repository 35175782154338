import React, { useState, useEffect } from 'react';

const LoadingScreen = () => {
  const [text, setText] = useState('');
  const fullText = 'DIB CAPITAL';

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 200); // Adjust timing as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#0A0E17',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999
    }}>
      <div style={{ textAlign: 'center' }}>
        <h1 style={{
          fontSize: '3rem',
          fontWeight: 'bold',
          color: '#00FFFF',
          letterSpacing: '0.1em'
        }}>
          {text}
          <span style={{
            borderRight: '0.08em solid #00FFFF',
            animation: 'blink-caret 0.75s step-end infinite'
          }}></span>
        </h1>
      </div>
      <style>
        {`
          @keyframes blink-caret {
            from, to { border-color: transparent }
            50% { border-color: #00FFFF; }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingScreen;